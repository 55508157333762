import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
//import { FormGroup, ControlContainer, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Product, ProductElement } from '@models/product';
import { Vendor } from '@models/vendor';
import { VendorService } from '@services/vendor.service';
import { environment } from '@environments/environment';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { AuthService } from '@services/auth.service';

import { map } from 'rxjs/operators';

@Component({
  selector: 'app-autocomplete-vendors',
  providers: [VendorService],
  templateUrl: './autocomplete-vendors.component.html',
  styleUrls: ['./autocomplete-vendors.component.css']
})
export class AutocompleteVendorsComponent implements OnInit {
	@Input() placeholder: string;
	@Input() required: boolean;
	// @Input() public parentForm: FormGroup;
	//@Input() public productData: ProductElement;
 	@ViewChild(MatAutocomplete, {static: false}) matAutocomplete: MatAutocomplete;
	//vendorCtrl: FormControl;
	filteredVendors: Vendor[];
  allVendors: Vendor[] = [];

	@Input() productForm: FormGroup;

	// @Output()
 //  productFormChange = new EventEmitter<FormGroup>();
 //  private _productForm: FormGroup;
	// get productForm(): FormGroup {
	// 	return this._productForm;
	// }
	// @Input()
	// set productForm(val: FormGroup) {
	// 	this._productForm = val;
	// 	this.loaded = true;
	// 	this.productFormChange.emit(this._productForm);
	// }

	@Output()
  fieldOpenChange = new EventEmitter<boolean>();
  private _fieldOpen: boolean;
	get fieldOpen(): boolean {
		return this._fieldOpen;
	}
	@Input()
	set fieldOpen(val: boolean) {
		this._fieldOpen = val;
		this.fieldOpenChange.emit(this._fieldOpen);
	}

	@Output()
  productDataChange = new EventEmitter<ProductElement>();
	private _productData: ProductElement;
	get productData(): ProductElement {
		return this._productData;
	}
	@Input()
	set productData(val: ProductElement) {
		this._productData = val;
		this.productDataChange.emit(this._productData);
		// this.vendorCtrl.setValue('test');
	}

/*
	@Output()
  vendorChange = new EventEmitter<Vendor>();
	private _vendor: Vendor;
	get vendor(): Vendor {
		return this._vendor;
	}
	@Input()
	set vendor(val: Vendor) {
		this._vendor = val;
		this.vendorCtrl.setValue(val);
		if (this._vendor._id) {
			this.filteredVendors = this.vendorService.getAllVendors();
		}
	}
*/
	public _parentForm: FormGroup;
	get parentForm(): FormGroup {
		return this._parentForm;
	}
	@Input()
	set parentForm(val: FormGroup) {
		this._parentForm = val;
	}

	constructor(private router: Router, private vendorService: VendorService, private authService: AuthService) {
		//this.vendorCtrl = new FormControl();
		this.getVendors();
    // this._vendorControl.valueChanges
	   //  .pipe(map(vendor => {
	   //  		if (vendor) {
	   //  			if (vendor instanceof Vendor || vendor instanceof Object) {
	   //  				return this._filter(vendor.name);
	   //  			} else {
	   //  				return this._filter(vendor);
	   //  			}
	   //  		} else {
	   //  			return this.allVendors.slice();
	   //  		}
	   //  	}))
	   //  .subscribe(res => { return this.filteredVendors = res; });


	}

  getVendors() {
		this.vendorService.getAllVendors().subscribe((res: Vendor[]) => {
			this.allVendors = [];
			for (let x = 0; x < res.length; x++) {
        this.allVendors.push(res[x]);
			}
			this.filteredVendors = this.allVendors;
		});

  }

  private _filter(vendor: string): Vendor[] {
    //const filterValue = vendor.name.toLowerCase();
    const filterValue = vendor.toLowerCase();

    return this.allVendors.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0 && !option.distributor);
  }

	displayVendorName(vendor: Vendor): string {
      return vendor ? vendor.name : '';
   }

	selectVendor($event, vendor: Vendor): string {
		if ($event.source.selected) {
			// this._productData.product.vendor = vendor;
    	this._fieldOpen = true;
    	this.fieldOpenChange.emit(true);
			// this.productDataChange.emit(this._productData);

    	//this.vendorChange.emit(vendor);
			//this._parentForm.controls.vendor.setValue(vendor.name)
		}
		return 'test';
	}

   chooseFirstOption(): void {
     //console.log("SELECT FIRST")
    //this.matAutocomplete.options.first.select();
  }

  clickField($event) {
    this._fieldOpen = true;
  	this.fieldOpenChange.emit(true);
  }

  blurField($event) {
    // if (this.filteredVendors.length == 1) {
    // 	this.productForm.get('vendor').setValue(this.filteredVendors[0]);
    // } else if(this.productForm.get('vendor').value) {
    // 	let exactMatch: Vendor = null;
    // 	this.filteredVendors.forEach((vendor:Vendor) => {
    // 		let vendorValue = (typeof this.productForm.get('vendor').value === 'string') ? this.productForm.get('vendor').value.toUpperCase() : this.productForm.get('vendor').value.name.toUpperCase();
    // 		if (vendor.name.toUpperCase() == vendorValue) {
    // 			exactMatch = vendor;
    // 		}
    // 	});
    // 	if (exactMatch) {
    // 		this.productForm.get('vendor').setValue(exactMatch);
    // 	} else {
    // 		//this.productForm.get('vendor').setValue(null);
    // 	}
    // }
  }

  ngOnInit() {
    //this.vendorControl.setValue(this._productData.product.vendor);
  }

	ngAfterViewInit() {
		let vendorControl = <FormControl>this.productForm.get('vendor');
    vendorControl.valueChanges
	    .pipe(map(vendor => {
	    		if (vendor) {
	    			if (vendor instanceof Vendor || vendor instanceof Object) {
	    				return this._filter(vendor.name);
	    			} else {
	    				return this._filter(vendor);
	    			}
	    		} else {
	    			return this.allVendors.slice();
	    		}
	    	}))
	    .subscribe(res => { return this.filteredVendors = res; });
  }
}
