<mat-form-field style="width:300px;" floatLabel="never">
  <div style="display: flex"><input type="text" [placeholder]="'LAST, FIRST'" matInput [formControl]="patientCtrl" [matAutocomplete]="auto" (keyup.enter)="fixDuplicates ? null : chooseFirstOption()" (click)="clickField($event)">
  <mat-checkbox *ngIf="canFixDuplicate" [(ngModel)]="fixDuplicates" [matTootltip]="'Fix Duplicates'"></mat-checkbox>  
  
  </div>
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayPatientName">
    <mat-option *ngFor="let patient of filteredPatients | async" (onSelectionChange)="fixDuplicates ? null : selectPatient(patient)" (click)="fixDuplicates ? $event.stopPropagation() : null">
      <ng-container *ngIf="fixDuplicates; else noFix">
        <div (click)="selectModalPatients($event, patient);">
          <mat-checkbox [checked]="selectedPatients.includes(patient)">
            {{ patient.lastName }}, {{ patient.firstName }} <span class="dob" *ngIf="patient.dob!=''">({{ patient.dob }})</span> <span class="sleep" *ngIf="patient.sleep"> SLEEP</span>
          </mat-checkbox>
        </div>
      </ng-container>
      <ng-template #noFix>
        {{ patient.lastName }}, {{ patient.firstName }} <span class="dob" *ngIf="patient.dob!=''">({{ patient.dob }})</span> <span class="sleep" *ngIf="patient.sleep"> SLEEP</span>
      </ng-template>
    </mat-option>
    <mat-option *ngIf="!fixDuplicates && mode!='importCustomerImages' && mode!='exams'" (onSelectionChange)="gotoNewPatient()">
      +NEW CUSTOMER
    </mat-option>
  </mat-autocomplete>
  <mat-label>{{placeholder}}</mat-label>
</mat-form-field>