import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { switchMap, takeWhile } from 'rxjs/operators';

import { MatDialog } from '@angular/material/dialog';

import { SaveComponent } from '@components/admin/dialog/save/save.component';
import { ReturnOrderDialogComponent } from '@components/admin/patient/return-order-dialog/return-order-dialog.component';
import { DeleteComponent } from '@components/admin/dialog/delete/delete.component';
import { OkCancelComponent } from '@components/admin/dialog/ok-cancel/ok-cancel.component';

import { FormatData } from '@classes/format-data';
import { PatientForm } from '@classes/patient-form';
import { OrderForm } from '@classes/order-form';

import { Patient } from '@models/patient';
import { PatientService } from '@services/patient.service';

import { Order, LogElement } from '@models/order';
import { OrderService } from '@services/order.service';

import { Prescription } from '@models/prescription';
import { PrescriptionService } from '@services/prescription.service';
import { AuthService } from '@services/auth.service';
import { Store } from '@models/store';
import { StoreService } from '@services/store.service';

import { FileUploader } from 'ng2-file-upload';
import { environment } from '@environments/environment';

import { parsePhoneNumber } from 'libphonenumber-js';

import * as ClassicEditorBuild from '@ckeditor/ckeditor5-build-classic';
import { Subscription } from 'rxjs';
import { EyeExamService } from '@services/eye-exam.service';
import { pick, omit } from 'lodash';
import moment from 'moment';
import { NotificationService } from '@services/notification.service';
import { PatientDialogComponent } from '../dialog/patient/patient-dialog.component';

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.css'],
})
export class PatientComponent implements OnInit {
  @Output() clickEvent: EventEmitter<any> = new EventEmitter();
  patient: Patient;
  patientId: string;
  newPatient: boolean = false;
  newPatientName: string;
  patientForm: FormGroup;
  patientDob: string;
  saveButtonClicked: Boolean = false;
  loadingOrders: Boolean = true;
  loadingPrescriptions: Boolean = true;

  public orderImages: String[] = [];
  public orders: Order[] = [];
  public prescriptions: Prescription[] = [];
  public prescriptionImages: String[] = [];
  public patientOrders: Order[] = [];
  public uploaderOrder: FileUploader = new FileUploader({
    url: environment.base_url + '/image/order',
    itemAlias: 'photo',
  });
  public uploaderPrescription: FileUploader = new FileUploader({
    url: environment.base_url + '/image/prescription',
    itemAlias: 'photo',
  });
  orderForms: FormArray[] = [];
  storeOptions: any[];
  authorizedRoles: string[] = ['MANAGER', 'OWNER/ADMIN'];
  authorized: boolean = false;
  loading: boolean = true;
  currentStore: Store;
  storeSubscription: Subscription;
  panelOpenStatePatientInformation: boolean = false;
  panelOpenStateInquiries: boolean = false;
  panelOpenStateOrders: boolean = false;
  panelOpenStateDoctorInformation: boolean = false;
  panelOpenStatePrescriptions: boolean = false;
  panelOpenStateScannedOrders: boolean = false;

  public ClassicEditorBuild = ClassicEditorBuild;
  canCopy: boolean = false;
  discounts = [
    { value: 'EMPLOYEE', viewValue: 'EMPLOYEE' },
    { value: 'FRIENDSFAMILY', viewValue: 'FRIENDS & FAMILY' },
    { value: 'SENIOR', viewValue: 'SENIOR' },
  ];

  canDeactivate() {
    if (this.patientForm.dirty && !this.saveButtonClicked) {
      return window.confirm('Discard changes?');
    }
    return true;
  }

  constructor(
    private patientService: PatientService,
    private orderService: OrderService,
    private prescriptionService: PrescriptionService,
    public storeService: StoreService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private auth: AuthService,
    private notificationService: NotificationService,
    private cdRef: ChangeDetectorRef,
    private eyeExamService: EyeExamService
  ) {
    this.storeOptions = this.storeService.stores;
    this.patientForm = this.fb.group({});

    this.storeSubscription = this.auth
      .getStoreObjectMessage()
      .subscribe((store: Store) => {
        this.currentStore = store;
      });

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.patientId = params.get('id');
      this.newPatient = this.router.url.includes('/admin/patient/new');
      this.newPatientName = params.get('name');
      if (!this.newPatient && this.patientId) {
        this.getPatient();
      } else if (this.newPatient || !this.patientId) {
        this.patient = new Patient();
        this.newPatient = true;
        this.setPatientForm();
        this.setNewPatientName(this.newPatientName);
      }
    });
  }

  ngAfterViewInit() {
    this.authorized = this.authorizedRoles.includes(this.auth.currentUser.role);

    const { exam } = this.route.snapshot.queryParams;
    if (this.newPatient && exam) {
      this.fillPatientWithExam(exam);
    }

    this.cdRef.detectChanges();
  }

  getPatient() {
    this.patientService.getPatient(this.patientId).subscribe((patient) => {
      this.patient = patient;
      this.setPatientForm();
      if (patient.alertNotes) {
        this.showPatientAlert();
      }
    });
  }

  setPatientForm() {
    this.patientForm = PatientForm.initPatientFormGroup(
      this.patient,
      this.auth,
      this.storeOptions
    );
    this.loading = false;
  }

  blurDate() {
    this.patientForm.get('dob').setValue(this.patientForm.get('dob').value);
  }

  blurDate2(prescriptionForm) {
    prescriptionForm.setValue(prescriptionForm.value);
  }

  ngOnInit(): void {
    this.canCopy = /admin/gi.test(this.auth.currentUser.role);
  }

  getPrefix(sex: string): string {
    let prefix;
    switch (sex) {
      case 'Male':
        prefix = 'MR';
        break;
      case 'Female':
        prefix = 'MS';
        break;
      default:
        break;
    }
    return prefix;
  }

  fillPatientWithExam(examId: string) {
    this.eyeExamService.findById(examId).subscribe((exam) => {
      this.patient = {
        ...this.patient,
        store: this.auth.getStoreObject()._id,
        ...pick(exam, [
          'internalPatientId',
          'firstName',
          'lastName',
          'address',
          'city',
          'state',
          'zip',
          'email',
          'occupation',
          'dob',
          'phone',
          'cell',
        ]),
        lastExam: exam.lastExam
          ? moment.utc(exam.lastExam).format('YYYY-MM-DD')
          : null,
        prefix: this.getPrefix(exam.sex),
        prescriptions: exam.prescriptions,
        from2020Now: true,
      };
      this.setPatientForm();
    });
  }

  prepareSavePatient(): Patient {
    const formModel = this.patientForm.value;
    const date = new Date(formModel.dob);
    const patientDob =
      formModel.dob != null &&
      formModel.dob !== '' &&
      date instanceof Date &&
      !isNaN(date.valueOf())
        ? date.toString()
        : null;
    const orderIds = this.orders.map((a) => a._id);
    const prescriptionIds = this.patient.prescriptions.map(
      (a: any) => a._id || ''
    );

    const savePatient: Patient = {
      _id: this.patient._id as string,
      internalPatientId: this.patient.internalPatientId,
      prefix: formModel.prefix as string,
      patientNumber: formModel.patientNumber as string,
      store: formModel.store as string,
      dob: patientDob as string,
      dateAdded: new Date().toString(),
      dateUpdated: new Date().toString(),
      discountStatus: formModel.discountStatus as string,
      storeCredit: FormatData.formatFloat(formModel.storeCredit) as number,
      firstName: formModel.firstName as string,
      lastName: formModel.lastName as string,
      address: formModel.address as string,
      address2: formModel.address2 as string,
      city: formModel.city as string,
      state: formModel.state as string,
      zip: formModel.zip as string,
      phone: formModel.phone as string,
      phone2: formModel.phone2 as string,
      cell: formModel.cell as string,
      email: formModel.email as string,
      since: formModel.since as string,
      badAddress: formModel.badAddress as string,
      occupation: formModel.occupation as string,
      interests: formModel.interests as string,
      favoriteLines: formModel.favoriteLines as string[],
      familyMembers: formModel.familyMembers as string,
      beBackList: formModel.beBackList as string,
      howDidYouFindUs: formModel.howDidYouFindUs as string,
      howDidYouFindUsOther: formModel.howDidYouFindUsOther as string,
      patientNotes: formModel.patientNotes as string,
      sleep: !!formModel.sleep as boolean,
      optOut: !!formModel.optOut as boolean,
      _doctorId: '',
      doctorName: formModel.doctorName as string,
      doctorPhone: formModel.doctorPhone as string,
      doctorFax: formModel.doctorFax as string,
      doctorEmail: formModel.doctorEmail as string,
      lastExam: formModel.lastExam as string,
      callForRx: !!formModel.callForRx as boolean,
      //prescriptions: '',
      prescriptionNotes: formModel.prescriptionNotes as string,
      //prescriptionImageIDs: '',
      //images: this.patient.images,
      //images2: this.patient.images2,
      //imageData: []
      orders: orderIds,
      prescriptions:
        this.patient.prescriptions.length > 0 && !prescriptionIds.join('')
          ? this.patient.prescriptions
          : prescriptionIds,
      from2020Now: formModel.from2020Now,
      alertNotes: formModel.alertNotes,
    };
    return savePatient;
  }

  prepareSaveOrders() {
    for (
      let x = 0;
      x < this.patientForm.controls.orderForms['controls'].length;
      x++
    ) {
      const saveOrder =
        this.patientForm.controls.orderForms['controls'][x].value;
      const order = new Order();
      order._id = saveOrder._id;
      order.invoiceNumber = saveOrder.invoiceNumber;
      order.type = saveOrder.type;
      order.archived = saveOrder.archived;
      order.patient = this.patient['_id'];
      this.route.paramMap
        .pipe(
          switchMap((params: ParamMap) =>
            this.orderService.updateOrder(saveOrder._id, order)
          )
        )
        .subscribe((order) => {
          console.log('order updated ' + order._id);
        });
    }
  }

  prepareSavePrescriptions() {
    for (
      let x = 0;
      x < this.patientForm.controls.prescriptionForms['controls'].length;
      x++
    ) {
      const savePrescription: Prescription = this.patientForm.controls
        .prescriptionForms['controls'][x].value as Prescription;
      const date = new Date(savePrescription.expirationDate);
      savePrescription.expirationDate =
        savePrescription.expirationDate != null &&
        savePrescription.expirationDate != '' &&
        date instanceof Date &&
        !isNaN(date.valueOf())
          ? date.toString()
          : null;
      savePrescription.patientId = this.patient['_id'];

      this.route.paramMap
        .pipe(
          switchMap((params: ParamMap) =>
            this.prescriptionService.updatePrescription(
              savePrescription._id,
              savePrescription
            )
          )
        )
        .subscribe((prescription) => {
          console.log('prescription updated ' + prescription._id);
        });
    }
  }

  save(): void {
    if (!this.saveButtonClicked) {
      this.patient = this.prepareSavePatient();
      // this.prepareSaveOrders();
      // this.prepareSavePrescriptions();
      this.saveButtonClicked = true;

      if (this.newPatient) {
        this.patientService
          .addPatient(omit(this.patient, ['_id']))
          .subscribe((res) => {
            this.notificationService.show('success', 'PATIENT SAVED', 'OK');
            this.patient._id = res['patient']['_id'];
            this.router.navigate(['/admin/patient', this.patient._id]);
          });
      } else {
        this.patientService.updatePatient(this.patient).subscribe((patient) => {
          this.notificationService.show('success', 'PATIENT SAVED', 'OK');
          this.router.navigateByUrl('/admin');
        });
      }
    }
  }

  copyPatient(): void {
    const dialogRef = this.dialog.open(PatientDialogComponent, {
      width: '800px',
      data: { patients: [this.patient], message: 'COPY PATIENT?' },
    });
    dialogRef.afterClosed().subscribe((patient?: Patient) => {
      if (patient) {
        this.router
          .navigate(['admin', 'patient', patient._id])
          .then(() => location.reload());
      }
    });
  }

  deletePatient(): void {
    const dialogRef = this.dialog.open(DeleteComponent, {
      width: '250px',
      data: { message: 'DELETE PATIENT?' },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == true) {
        this.patientService
          .deletePatient(this.patient._id)
          .subscribe((patient) => {
            this.notificationService.show('success', 'PATIENT DELETED', 'OK');
            this.router.navigateByUrl('/admin');
          });
      }
    });
  }

  setOrderAsPrescription(inputObj): void {
    const orderId = inputObj._id;
    const index = inputObj.index;
    const dialogRef = this.dialog.open(OkCancelComponent, {
      width: '250px',
      data: { message: 'SET ORDER AS PRESCRIPTION?' },
    });
    dialogRef
      .afterClosed()
      .pipe(takeWhile((result) => !!result))
      .subscribe(() => {
        this.orderService
          .setOrderAsPrescription(orderId)
          .subscribe((prescription) => {
            let orderFormArray: FormArray;
            orderFormArray = this.patientForm.controls.orderForms as FormArray;
            orderFormArray.removeAt(index);
            const newPrescriptionFormGroup = OrderForm.initOrderFormGroup(
              prescription,
              this.auth,
              this.storeOptions
            );
            const patientPrescriptionsFormArray: FormArray =
              this.patientForm.get('prescriptions') as FormArray;
            patientPrescriptionsFormArray.controls.unshift(
              newPrescriptionFormGroup
            );
            this.notificationService.show(
              'success',
              'ORDER CHANGED TO PRESCRIPTION',
              'OK'
            );
          });
      });
  }

  deleteOrder(inputObj): void {
    console.log(inputObj);
    const orderId = inputObj._id;
    const index = inputObj.index;
    const dialogRef = this.dialog.open(DeleteComponent, {
      width: '250px',
      data: { message: 'DELETE ORDER?' },
    });
    dialogRef
      .afterClosed()
      .pipe(takeWhile((result) => !!result))
      .subscribe(() => {
        this.orderService.deleteOrder(orderId).subscribe((order) => {
          // var orderFormArray: FormArray;
          // orderFormArray = this.patientForm.controls.orderForms as FormArray;
          // orderFormArray.removeAt(index);
          this.openDialog('ORDER DELETED');
          this.notificationService.show('success', 'ORDER DELETED', 'OK');
        });
      });
  }

  returnOrder(inputObj): void {
    const order: Order = inputObj.order;
    const orderId = order._id;
    const index = inputObj.index;
    const dialogRef = this.dialog.open(ReturnOrderDialogComponent, {
      width: '250px',
      data: { message: 'RETURN ORDER?', order: order },
    });
    dialogRef
      .afterClosed()
      .pipe(takeWhile((result) => !!result))
      .subscribe(() => {
        let credit = dialogRef.componentInstance.credit;
        if (typeof credit === 'string') {
          credit = parseFloat(credit.replace(/,/g, ''));
        }

        const logItem = new LogElement();
        logItem.initials = order['initials'];
        logItem.user = this.auth.currentUser._id;
        logItem.item = 'ORDER RETURNED';
        logItem.notes = '';
        logItem.date = Date.now().toString();
        order.log.unshift(logItem);
        order.invoiceNumber = order.invoiceNumber + '-R';
        order.patient = this.patientId;

        this.orderService
          .returnOrder(order, credit)
          .subscribe((order: Order) => {
            const storeCreditControl = this.patientForm.get('storeCredit');
            const storeCreditValue =
              FormatData.formatFloat(storeCreditControl.value) +
              FormatData.formatFloat(credit);
            storeCreditControl.setValue(storeCreditValue);
            this.notificationService.show('success', 'ORDER RETURNED', 'OK');
          });
      });
  }

  exchangeOrder(inputObj): void {
    const order: Order = inputObj.order;
    const orderId = order._id;
    const index = inputObj.index;
    const dialogRef = this.dialog.open(ReturnOrderDialogComponent, {
      width: '250px',
      data: { message: 'EXCHANGE ORDER?', order: order },
    });
    dialogRef
      .afterClosed()
      .pipe(takeWhile((result) => !!result))
      .subscribe(() => {
        let credit = dialogRef.componentInstance.credit;
        if (typeof credit === 'string') {
          credit = parseFloat(credit.replace(/,/g, ''));
        }

        const logItem = new LogElement();
        logItem.initials = order['initials'];
        logItem.user = this.auth.currentUser._id;
        logItem.item = 'ORDER EXCHANGED';
        logItem.notes = '';
        logItem.date = Date.now().toString();
        order.log.unshift(logItem);
        order.invoiceNumber = order.invoiceNumber + '-R';
        order.patient = this.patientId;

        this.orderService
          .returnOrder(order, credit)
          .subscribe((order: Order) => {
            const storeCreditControl = this.patientForm.get('storeCredit');
            const storeCreditValue =
              FormatData.formatFloat(storeCreditControl.value) +
              FormatData.formatFloat(credit);
            storeCreditControl.setValue(storeCreditValue);
            this.notificationService.show('success', 'ORDER EXCHANGED', 'OK');
          });
      });
  }

  updatePatientCredit(storeCredit) {}

  openDialog(message): void {
    const dialogRef = this.dialog.open(SaveComponent, {
      width: '250px',
      data: { message: message },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }

  blurPhone(prescriptionForm) {
    const phoneNumber = parsePhoneNumber(prescriptionForm.value, 'US');
    prescriptionForm.setValue(phoneNumber.formatNational());
  }

  phoneType(event, field) {
    const phoneNumber = parsePhoneNumber(
      this.patientForm.controls[field].value,
      'US'
    );
    this.patientForm.controls[field].setValue(phoneNumber.formatNational());
  }

  importCustomerImages() {
    this.router.navigate(['/admin/import-customer-images', this.patient._id]);
  }

  setNewPatientName(name) {
    if (name != undefined) {
      const lastFirst = [];
      let lastName = '';
      let firstName = '';
      if (name.includes(',')) {
        const pos = name.indexOf(',');
        lastName = name.slice(0, pos).trim();
        firstName = name.slice(pos + 1).trim();
      } else if (name.includes(' ')) {
        const pos = name.indexOf(' ');
        lastName = name.slice(0, pos).trim();
        firstName = name.slice(pos + 1).trim();
      }
      this.patientForm.get('firstName').setValue(firstName);
      this.patientForm.get('lastName').setValue(lastName);
    }
  }

  showPatientAlert(): void {
    this.dialog.open(OkCancelComponent, {
      width: '400px',
      height: '400px',
      closeOnNavigation: true,
      hasBackdrop: true,
      disableClose: true,
      id: 'acknowledge',
      data: {
        message: this.patient.patientNotes,
        hideCancel: true,
        confirmText: 'ACKNOWLEDGE',
        postIt: true,
      },
    });
  }
}
