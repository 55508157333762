import {
  Component,
  OnInit,
  Input,
  Output,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { Patient } from '@models/patient';
import { PatientService } from '@services/patient.service';
import { environment } from '@environments/environment';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { AuthService } from '@services/auth.service';

import { debounceTime, map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { PatientDialogComponent } from '../dialog/patient/patient-dialog.component';
import { fork } from 'child_process';

@Component({
  selector: 'app-autocomplete-patient',
  providers: [PatientService],
  templateUrl: './autocomplete-patient.component.html',
  styleUrls: ['./autocomplete-patient.component.css'],
})
export class AutocompletePatientComponent implements OnInit{
  @Input() mode: string;
  @Input() placeholder: string;
  @Input() stopPropagation: boolean;
  @Output() selectedPatient: EventEmitter<Patient> =
    new EventEmitter<Patient>();
  @ViewChild(MatAutocomplete, { static: false })
  matAutocomplete: MatAutocomplete;
  private base_url = environment.base_url + '/api/patient';
  patientCtrl: FormControl;
  filteredPatients: any;
  canFixDuplicate: boolean = false;
  selectedPatients: Patient[] = [];
  fixDuplicates: boolean = false;

  constructor(
    private router: Router,
    private patientService: PatientService,
    private authService: AuthService,
    public dialog: MatDialog,
  ) {
    this.patientCtrl = new FormControl();
    this.authService.getStoreMessage().subscribe(() => {
      const emptyPatient: Observable<Patient[]> = of([]);
      this.patientCtrl.valueChanges
        .pipe(
          debounceTime(500),
          map((name) =>
            name ? this.patientService.queryPatients(name) : emptyPatient
          )
        )
        .subscribe((res) => {
          this.filteredPatients = res;
        });
    });
  }

  ngOnInit(): void {
    this.canFixDuplicate = /admin/gi.test(this.authService.currentUser.role);
  }

  displayPatientName(patient: Patient): string {
    return patient ? `${patient.lastName}, ${patient.firstName}` : '';
  }

  selectPatient(patient: Patient): string {
    switch (this.mode) {
      case 'importCustomerImages':
        this.router.navigate(['/admin/import-customer-images', patient._id]);
        break;
      case 'exams':
        this.selectedPatient.emit(patient);
        break;
      case 'editCustomer':
      default:
        this.router.navigate(['/admin/patient', patient._id]);
    }
    return 'done';
  }

  gotoNewPatient() {
    if (this.patientCtrl.value != null) {
      this.router.navigate(['/admin/patient/new', this.patientCtrl.value]);
    } else {
      this.router.navigate(['/admin/patient/new']);
    }
  }

  chooseFirstOption(): void {
    //console.log("SELECT FIRST")
    //this.matAutocomplete.options.first.select();
  }

  clickField(event) {
    if (this.stopPropagation) {
      event.stopPropagation();
    }
  }

  selectModalPatients(event: Event, patient: Patient) {
    event.stopPropagation();
    event.preventDefault();
    if(this.selectedPatients.includes(patient)){
      this.selectedPatients = this.selectedPatients.filter(p => p._id != patient._id);
    } else {
      this.selectedPatients.push(patient);
    }
    if(this.selectedPatients.length === 2) {
      const patient1 = this.patientService.getPatient(this.selectedPatients[0]._id);
      const patient2 = this.patientService.getPatient(this.selectedPatients[1]._id);
      forkJoin([patient1, patient2]).subscribe((res) => {
        console.log(res);
        this.fixDuplicate(res);
      });
    }
  }

  fixDuplicate(patients: Array<Patient>): void {
    const dialogRef = this.dialog.open(PatientDialogComponent, {
      width: '800px',
      data: { patients, message: 'FIX DUPLICATE' },
    });
    dialogRef.afterClosed().subscribe((patientId?: string | boolean) => {
      this.selectedPatients = [];
      if (typeof patientId === 'string') {
        this.router
          .navigate(['admin', 'patient', patientId]);
      }
    });
  }
  
  selectionsChanged(event) {
    console.log(event);
  }

  preventDefault(event) {
    event.preventDefault();
  }
}
