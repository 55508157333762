<mat-dialog-content>
  <h2>{{ data.message }}</h2>
  <p>Select the duplicate to keep</p>
  <div *ngIf="data.patients.length === 2">
    <mat-checkbox [checked]="selectedPatient === data.patients[0]" (change)="selectPatient($event.checked, data.patients[0]._id)"
      >{{ data.patients[0].firstName }}
      {{ data.patients[0].lastName }} <span class="dob" *ngIf="data.patients[0].dob!=''">({{ data.patients[0].dob  | date:'MM/dd/yy' }})</span> <span class="sleep" *ngIf="data.patients[0].sleep"> SLEEP</span></mat-checkbox
    >
    <span style="margin-right: 50px"></span>
    <mat-checkbox [checked]="selectedPatient === data.patients[1]" (change)="selectPatient($event.checked, data.patients[1]._id)"
      >{{ data.patients[1].firstName }}
      {{ data.patients[1].lastName }} <span class="dob" *ngIf="data.patients[1].dob!=''">({{ data.patients[1].dob  | date:'MM/dd/yy' }})</span> <span class="sleep" *ngIf="data.patients[1].sleep"> SLEEP</span></mat-checkbox
    >
  </div>
  <div *ngIf="selectedPatient && isCopyDataAvailable">
    <ng-container *ngIf="!deletePatient; else multiple">
      <p>
        This will copy the data for {{ selectedPatient.firstName }}
        {{ selectedPatient.lastName }} to a new patient. Select the
        prescriptions, order and favorite lines to associate with the patient
        copy. Note: orders and prescriptions will removed from the current
        patient.
      </p>
    </ng-container>
    <ng-template #multiple>
      <p>
        This will delete and move all data for {{ deletePatient.firstName }}
        {{ deletePatient.lastName }} to {{ selectedPatient.firstName }}
        {{ selectedPatient.lastName }}. Note: the deleted patient will no
        longer be available in the system.
      </p>
    </ng-template>
  </div>
  <div *ngIf="selectedPatient && !isCopyDataAvailable">
    <p>No orders or prescriptions to associated with {{ data.patients.length === 2 ? 'new patient' : 'patient to be deleted' }}, no data will be transferred. The patient only will be {{ data.patients.length === 2 ? 'deleted' : 'copied'}}.</p>
  </div>

  <div class="d-flex flex-column" *ngIf="os.length">
    <label>{{
      !deletePatient
        ? 'Select orders to associate with new patient'
        : 'Orders that will be moved to selected patient'
    }}</label>
    <div class="d-flex orders">
      <div *ngFor="let order of os" class="ao-checkbox order odd-color">
        <span><label>Invoice#: </label>{{ order.invoiceNumber }}</span>
        <input
        *ngIf="!deletePatient"
          type="checkbox"
          [checked]="selectedOrders[order._id]"
          (change)="selectOrder(order._id)"
        />
      </div>
    </div>
  </div>

  <div class="d-flex-full flex-column" *ngIf="prescriptionData.length">
    <label>{{
      !deletePatient
        ? 'Select prescriptions to associate with new patient'
        : 'Prescription that will be moved to selected patient'
    }}</label>
    <div class="d-flex-full prescriptions">
      <div
        *ngFor="let p of prescriptionData"
        class="d-flex flex-column odd-color"
        style="width: 33%"
      >
        <div class="eye-type ao-checkbox prescription">
          <label>{{ p.type }} | {{ p.date | date : 'MM/dd/yy' }}</label>
          <input
          *ngIf="!deletePatient"
            type="checkbox"
            [checked]="selectedPrescriptions[p._id]"
            (change)="selectPrescription(p.id)"
          />
        </div>
        <!-- <div class="d-flex">
            <div class="d-flex flex-column eye">
              <h5>Left</h5>
              <div *ngFor="let entry of p.left">
                <label>{{ entry.label }}</label>
                <p>{{ entry.value }}</p>
              </div>
            </div>
            <div class="d-flex flex-column eye">
              <h5>Right</h5>
              <div *ngFor="let entry of p.right">
                <label>{{ entry.label }}</label>
                <p>{{ entry.value }}</p>
              </div>
            </div>
          </div> -->
      </div>
    </div>
  </div>


</mat-dialog-content>
<mat-dialog-actions>
  <button *ngIf="data.patients.length === 2" mat-raised-button [disabled]="!selectedPatient" (click)="removeDuplicate()">CONFIRM</button>
  <button *ngIf="data.patients.length === 1" mat-raised-button (click)="confirm()">CONFIRM</button>
  <button mat-raised-button (click)="cancel()">CANCEL</button>
</mat-dialog-actions>
