import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Vendor } from '@models/vendor';
import { VendorOrder, VendorOrders } from '@models/vendor-order';
import { PrintService } from '@services/print.service';
import { VendorOrderService } from '@services/vendor-order.service';
import { VendorService } from '@services/vendor.service';
import ObjectID from 'bson-objectid';
import {
  catchError,
  combineLatest,
  concatMap,
  debounceTime,
  EMPTY,
  filter,
  map,
  of,
  Subject,
  take,
  takeUntil,
  tap,
  timer,
} from 'rxjs';
import { SortDirection } from '@angular/material/sort';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackbarComponent } from 'src/app/v2/common/snackbar/snackbar.component';
import { AuthService } from '@services/auth.service';
import { PayableSelectionService } from 'src/app/v2/shared/services/payable-selection.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PayableOrderDialogComponent } from '../payable-order/payable-order.dialog.component';
import { AlertService } from '@services/alert.service';
import { Payable } from '@models/api.model';
import { routeParams } from 'src/app/v2/shared/utils/route';
import {
  getDisplayedColumns,
  getNumberPrecision,
  setGroupMap,
  setOrders,
  sortData,
  searchVendorOrder,
  searchPayable,
} from '@v2/shared/utils';
import { OrderDetails } from '@v2/shared/models';
@Component({
  selector: 'app-payables-list',
  templateUrl: './payables-list.component.html',
  styleUrls: ['./payables-list.component.scss'],
})
export class PayablesListComponent implements AfterViewInit, OnDestroy {
  vendor: Vendor;
  vendorId: string;
  distributorId: string;
  status = 'unpaid';
  orders: Array<OrderDetails>;
  ordersRef: Array<OrderDetails>;
  filteredOrders: Array<OrderDetails>;
  payables: Array<Payable>;
  unsubscribe$ = new Subject<void>();
  sort: Sort = { active: 'date', direction: 'asc' };
  displayedColumns: string[] = [];
  dataSource = new Subject<MatTableDataSource<OrderDetails>>();
  @ViewChild('matCardHeader') header: ElementRef;
  @ViewChild(MatTable) table: MatTable<OrderDetails>;
  selectedGroups: Record<string, boolean> = {};
  selectedOrders: Record<string, boolean> = {};
  selectedOrderCount = 0;
  selectedOrderTotal = 0;
  awaitingOrders: Record<string, boolean> = {};
  groupMap: Record<string, any> = {};
  searchControl: FormControl;
  allUnpaidSelected = false;
  minimized = false;
  snackBarRef: MatSnackBarRef<SnackbarComponent>;
  vendorNames: Record<string, string> = {};
  invoiceDialogRef: MatDialogRef<PayableOrderDialogComponent>;
  focus: string;
  aside: boolean;
  vendorTaxBoth: Record<string, boolean> = {};

  logForm = new FormGroup({
    date: new FormControl(new Date().toISOString()),
    notes: new FormControl(null),
    user: new FormControl(this.aS.currentUser._id),
    initials: new FormControl(null, [
      Validators.minLength(2),
      Validators.required,
    ]),
  });

  get someSelected() {
    return !!Object.keys(this.selectedOrders)?.length ?? 0;
  }

  @HostListener('document:click', ['$event']) clickFocus(event: any) {
    const {
      target,
      target: { dataset },
    } = event;
    if (dataset?.focus) {
      this.focus = dataset.focus;
      this.table.renderRows();
    } else {
      this.focus = null;
    }
  }

  constructor(
    private dialog: MatDialog,
    private als: AlertService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly aS: AuthService,
    private readonly pS: PrintService,
    private readonly vS: VendorService,
    private readonly voS: VendorOrderService,
    private readonly psS: PayableSelectionService,
    private readonly cd: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this.searchControl = new FormControl(
      this.route.snapshot.queryParams.search ?? null
    );
    this.searchControl.valueChanges
      .pipe(debounceTime(2000), takeUntil(this.unsubscribe$))
      .subscribe((searchText: string) => this.searchOrders(searchText));
    this.getOrderData();
    this.onRouterChange();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.dialog.closeAll();
  }

  filterOrders(payables: Array<Payable>) {
    const { search } = this.route.snapshot.queryParams;
    if (search?.length) {
      const matchedOrders = payables?.reduce(
        (acc: Array<Payable>, order: Payable) => {
          const matched = searchPayable(order, search);
          if (matched) {
            return [...acc, order];
          }
          return acc;
        },
        [] as Array<Payable>
      );
      return of(matchedOrders);
    }
    return of(payables);
  }

  formatVendorData(res: Array<Payable>, sort?: Sort) {
    this.cd.detectChanges();
    this.payables = res;
    if (this.status === 'paid') {
      this.setGroupMap(this.payables, sort);
      this.setOrders();
    } else {
      this.payables = res.map((item: Payable) => ({
        ...item,
        awaitingCredit: item.status === 'RETURNED',
        status: item.status === 'RETURNED' ? 'AWAITING CREDIT' : item.status,
        unPaid: item.status?.toLowerCase() !== 'paid',
      }));
      console.log(this.payables);
      this.orders = res.map((item: Payable) => ({
        ...item,
        awaitingCredit: item.status === 'RETURNED',
        status: item.status === 'RETURNED' ? 'AWAITING CREDIT' : item.status,
        unPaid: true,
      }));
      this.ordersRef = [...this.orders];
    }
    this.selectedOrders =
      this.psS.selectedOrders?.[this.vendorId ?? this.distributorId] ?? {};
    this.setSelectedCount();
    this.setDataSource();
  }

  getIdsFromRoute() {
    const { col, dir, vendorId, status, distributorId } = routeParams(
      this.route
    );
    this.setVendorId(vendorId);
    this.setDistributorId(distributorId);
    this.setStatus(status);
    this.setDisplayedColumns(status);
    this.setSort(col, dir);
    this.cd.detectChanges();
    return distributorId ?? vendorId;
  }

  setDistributorId(id: string) {
    this.distributorId = id;
  }

  setVendorId(id: string) {
    this.vendorId = id;
  }

  setDisplayedColumns(status?: string) {
    this.displayedColumns = getDisplayedColumns(status ?? this.status);
  }

  setSort(col?: string, dir?: SortDirection) {
    const { active, direction } = this.sort;
    this.sort = { active: col ?? active, direction: dir ?? direction };
  }

  setStatus(status: string) {
    this.status = status ?? 'unpaid';
  }

  getOrderData(vendorId?: string) {
    const id = vendorId ?? this.getIdsFromRoute();
    this.getVendor(id)
      .pipe(
        take(1),
        concatMap((payables: Array<Payable>) => {
          const data = payables.map((payable) => ({
            ...payable,
            amountToPay: getNumberPrecision(
              +payable.total - +payable.partialPayment
            ),
            tax: getNumberPrecision(payable.tax),
            shipping: getNumberPrecision(payable.shipping),
            total: getNumberPrecision(payable.total),
            partialPayment: getNumberPrecision(payable.partialPayment),
            amountDue: getNumberPrecision(
              +payable.total - +payable.partialPayment
            ),
            vendorName: this.vendorNames[payable.vendor],
            unPaid: payable.status?.toLowerCase() !== 'paid',
          }));

          const { col } = routeParams(this.route);
          if (col) {
            //console.log(data.map((d) => d[col]));
          }

          return this.sortData(data);
        })
      )
      .subscribe((res) => {
        this.formatVendorData(res, this.sort);
      });
  }

  getVendor(vendorId: string, resetSelections: boolean = false) {
    if (!vendorId) {
      return EMPTY;
    }
    return this.vS.getVendor(this.vendorId ?? this.distributorId).pipe(
      take(1),
      tap((vendor: Vendor) => {
        const { _id } = vendor;
        this.vendorNames[_id] = vendor.name;
        this.vendor = vendor;
        this.selectedOrders = {};
        this.cd.detectChanges();
        if (!this.orders?.length) {
          this.als.loading.next({
            isLoading: true,
            loadingMessage: `Loading ${vendor.name}`,
          });
        }
      }),
      concatMap((vendor: Vendor) => {
        const { distributorVendors, _id } = vendor;
        const ids = distributorVendors?.length
          ? [_id, ...distributorVendors]
          : [_id];
        return this.getVendorOrders(ids);
      })
    );
  }

  getVendorOrders(vendorIds: Array<string>) {
    if (!vendorIds?.length) {
      return EMPTY;
    }
    return this.vS.getVendorNames(vendorIds).pipe(
      take(1),
      tap((res) => {
        // this.vendorNames = { ...this.vendorNames, ...res };
        const entries: Array<
          [string, { name: string; taxBothRxAndSun: boolean }]
        > = Object.entries(res);
        const x = entries.reduce(
          (acc: any, [key, value]: [string, any]) => {
            const v = value as { name: string; taxBothRxAndSun: boolean };
            return {
              names: { ...acc.names, [key]: v.name },
              taxBothRxAndSun: {
                ...acc.taxBothRxAndSun,
                [key]: !!v.taxBothRxAndSun,
              },
            };
          },
          { names: { ...this.vendorNames }, taxBothRxAndSun: {} }
        );
        const { names, taxBothRxAndSun } = x;
        this.vendorNames = names;
        this.vendorTaxBoth = taxBothRxAndSun;
      }),
      concatMap(() =>
        this.voS.getVendorPayablesList(
          this.status ?? 'paid',
          vendorIds,
          this.route.snapshot.queryParams
        )
      )
    );
  }

  markVendorOrdersPaidAndPrint() {
    this.markVendorOrdersPaid(true);
  }

  markOrderPaid(id: string, order: Partial<VendorOrder>) {
    return this.voS.updateVendorOrderById(id, order).pipe(take(1));
  }

  markVendorOrdersPaid(print?: boolean) {
    const { date, initials, notes, user } = this.logForm.value;
    const updateStatus = 'PAID';
    const datePaid = new Date().toUTCString();
    const selectedOrderIds = Object.keys(this.selectedOrders);
    const selectedOrders = [...this.payables].filter(
      (p: Payable) => !!this.selectedOrders[p._id]
    );
    const paidGroupId = new ObjectID().toHexString();
    const payVendors = selectedOrders.map((order: Payable) => {
      const { total: orderTotal, amountToPay } = order;
      const ref = this.ordersRef.find((o) => o._id === order._id);
      const { partialPayment: refPartialPayment, total: refTotal } = ref;
      const total = +refTotal;
      const partialPayment = +refPartialPayment + +amountToPay;
      const isPayment = amountToPay && total - partialPayment > 1;
      const payments = !isPayment
        ? []
        : [
            {
              date: new Date().toUTCString(),
              amount: +amountToPay,
              groupId: paidGroupId,
              _id: new ObjectID().toHexString(),
            },
          ];
      return this.markOrderPaid(order._id, {
        log: [
          {
            _id: new ObjectID().toHexString(),
            date: new Date().toUTCString(),
            initials,
            user,
            item: isPayment ? `PAYMENT $${amountToPay}` : updateStatus,
          },
        ],
        payments,
        amountDue: isPayment ? total - partialPayment : 0,
        partialPayment: !isPayment ? null : partialPayment,
        partialPaymentDate: !isPayment ? null : datePaid,
        datePaid: isPayment ? null : datePaid,
        dateUpdated: datePaid,
        groupId: paidGroupId,
        status: isPayment ? order.status : 'PAID',
      });
    });

    combineLatest(payVendors)
      .pipe(
        map(() => this.getIdsFromRoute()),
        tap((vendorId: string) => {
          this.logForm.reset();
          selectedOrders.forEach((order: Payable) =>
            this.onSelectOrder(order._id)
          );
          this.setSelectedCount();
          this.getOrderData(vendorId);
          if (print) {
            this.printSelectedVendorOrders(selectedOrderIds);
          }
        }),
        catchError((err) => {
          return EMPTY;
        })
      )
      .subscribe();
  }

  markInvoiceCredited(orderId: string) {
    const order = this.orders.find((p) => p._id === orderId);
    const { invoiceNumber, shipping, tax, amountDue, total } = order;
    this.voS
      .updateVendorOrderById(orderId, {
        log: [
          {
            _id: new ObjectID().toHexString(),
            date: new Date().toUTCString(),
            initials: this.aS.initials.toUpperCase(),
            user: this.aS.userId,
            item: 'CREDITED',
          },
        ],
        invoiceNumber: order.invoiceNumber,
        status: 'CREDITED',
        shipping: Math.abs(+order.shipping) * -1,
        tax: Math.abs(+order.tax) * -1,
        amountDue: Math.abs(+order.amountDue) * -1,
        total: Math.abs(+order.total) * -1,
      })
      .pipe(
        map(() => this.getIdsFromRoute()),
        tap((vendorId: string) => {
          this.onSelectOrder(orderId);
          this.getOrderData(vendorId);
        }),
        catchError((err) => {
          return EMPTY;
        })
      )
      .subscribe();
  }

  onRouterChange() {
    this.router.events
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((event) => event instanceof NavigationEnd),
        map(() => this.getIdsFromRoute()),
        tap((vendorId: string) => {
          this.getOrderData(vendorId);
        })
      )
      .subscribe((event) => {
        window.scrollTo(0, 0);
      });
  }

  onSelectAllUnpaid() {
    if (this.allUnpaidSelected) {
      this.allUnpaidSelected = false;
      this.selectedGroups = {};
      this.selectedOrders = {};
      this.setSelectedCount();
      return;
    }

    this.selectedOrders = this.payables.reduce((acc, payable) => {
      if (payable.awaitingCredit) {
        return acc;
      }
      return { ...acc, [payable._id]: true };
    }, {});
    this.allUnpaidSelected = true;
    this.setSelectedCount();
  }

  onSelectGroup(id: string) {
    const { [id]: _, ...rest } = this.selectedGroups;
    const deselect = !!this.selectedGroups[id];
    const groupOrderIds = this.groupMap[id].orders.map((order) => order._id);
    if (deselect) {
      this.selectedGroups = rest;
      groupOrderIds.forEach((id) => {
        const { [id]: _, ...rest } = this.selectedOrders;
        this.selectedOrders = rest;
        this.allUnpaidSelected = false;
      });
    } else {
      this.selectedGroups[id] = true;
      groupOrderIds.forEach((orderId) => {
        this.selectedOrders[orderId] = true;
      });
    }
    this.setSelectedCount();
  }

  onSelectOrder(id: string) {
    const { [id]: _, ...rest } = this.selectedOrders;
    if (this.selectedOrders[id]) {
      this.selectedOrders = rest;
      this.allUnpaidSelected = false;
    } else {
      this.selectedOrders[id] = true;
    }
    this.setSelectedCount();
  }

  onSelectAwaitingCredit(id: string) {
    const { [id]: _, ...rest } = this.awaitingOrders;
    if (this.awaitingOrders[id]) {
      this.awaitingOrders = rest;
    } else {
      this.awaitingOrders[id] = true;
    }
  }

  onSortChange(sort: Sort) {
    const { active, direction } = sort;
    const {
      col: sortBy,
      dir: orderBy,
      ...rest
    } = this.route.snapshot.queryParams;

    let dir: SortDirection;
    if (orderBy) {
      dir = orderBy === 'desc' ? 'asc' : 'desc';
    } else if (!direction) {
      dir = 'desc';
    } else {
      dir = direction;
    }

    const queryParams = { ...rest, col: active, dir };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
    });
  }

  printSelectedVendorOrders(orderIds?: Array<string>) {
    this.pS.printVendorInvoices(orderIds ?? Object.keys(this.selectedOrders), this.vendor);
  }

  printVendorOrder(orderId: string) {
    this.pS.printVendorInvoices([orderId], this.vendor);
  }

  printVendorOrders(groupId: string) {
    const orderIds = this.groupMap[groupId]?.orders?.map(
      (order: Payable) => order._id
    );
    this.pS.printVendorInvoices(orderIds ?? [], this.vendor, groupId);
  }

  setSelectedCount() {
    this.selectedOrderCount = Object.keys(this.selectedOrders ?? {}).length;
    this.selectedOrderTotal = this.orders
      ?.filter((order) => !!this.selectedOrders[order._id])
      .reduce((acc, order) => acc + +order.amountToPay, 0);
    if (this.selectedOrderCount === this.orders.length) {
      this.allUnpaidSelected = true;
    }
    if (this.selectedOrderCount) {
      this.psS.setSelectedInvoices(
        this.vendorId ?? this.distributorId,
        this.selectedOrders
      );
    } else {
      this.psS.clearSelectedInvoices(this.vendorId ?? this.distributorId);
    }
    this.cd.detectChanges();
  }

  searchOrders(search: string) {
    this.als.loading.next({
      isLoading: true,
      loadingMessage: `Searching ${search}...`,
    });
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        ...this.route.snapshot.queryParams,
        search,
      },
    });
  }

  setDataSource() {
    if (this.table) {
      const orders = [...this.orders] ?? [];
      const dataSource = new MatTableDataSource(orders);
      this.dataSource.next(dataSource);
      this.table.renderRows();
      this.cd.detectChanges();
    }
    timer(1000).subscribe(() => this.als.loading.next({ isLoading: false }));
  }

  setGroupMap(payables: Array<Payable>, sort?: Sort) {
    this.groupMap = setGroupMap(payables, this.status, sort);
  }

  setOrders() {
    this.orders = setOrders(this.groupMap, this.status, this.sort);
    this.ordersRef = [...this.orders];
  }

  showPaid() {
    this.showStatus('paid');
  }

  showUnPaid() {
    this.showStatus('unpaid');
  }

  showStatus(status?: string) {
    this.als.loading.next({
      isLoading: true,
      loadingMessage: `Loading ${status}`,
    });
    const subUrl = this.vendorId
      ? `vendor/${this.vendorId}`
      : `distributor/${this.distributorId}`;
    this.router.navigate([`/v2/payables/${subUrl}/${status}`], {
      queryParams: {
        ...this.route.snapshot.queryParams,
      },
    });
  }

  sortData(payables: Array<Payable>) {
    const { col, dir } = this.route.snapshot.queryParams;
    if (col && dir) {
      const sort = new MatSort();
      sort.active = col;
      sort.direction = dir;
      this.sort = sort;
    }
    return sortData(payables, this.route, ['invoiceNumber', 'total']);
  }

  viewOrder(orderId: string) {
    this.router.navigate([`/v2/orders/${orderId}`], {
      queryParams: { distributorId: this.distributorId },
    });
  }

  editInvoice(orderId: string) {
    const vendorOrder = this.payables.find((order) => order._id === orderId);
    this.invoiceDialogRef = this.dialog.open(PayableOrderDialogComponent, {
      data: { order: vendorOrder },
      panelClass: 'minimal',
    });
    this.invoiceDialogRef
      .afterClosed()
      .pipe(tap(() => this.getOrderData()))
      .subscribe();
  }

  deleteInvoice(orderId: string) {
    this.voS
      .deleteVendorOrder(orderId)
      .pipe(
        take(1),
        tap(() => this.getOrderData())
      )
      .subscribe();
  }

  updatePayable(value: string, orderId: string, prop?: string) {
    const payable = this.payables.find((order) => order._id === orderId);
    const order = this.orders.find((order) => order._id === orderId);
    order[prop] = value;
    payable[prop] = value;
    if (payable?.awaitingCredit && prop === 'amountDue') {
      order.total = value;
      payable.total = value;
    }
    this.setSelectedCount();
  }
}
