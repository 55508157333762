import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ApiResponse } from '@classes/api-response';
import { Order } from '@models/order';
import { Patient } from '@models/patient';
import { Prescription } from '@models/prescription';
import { OrderService } from '@services/order.service';
import { PatientService } from '@services/patient.service';
import { catchError, concatMap, EMPTY, map, of, switchMap } from 'rxjs';

export interface PatientDialogData {
  patients: Array<Patient>;
  message: string;
}

export interface FlatPrescription {
  label: string;
  value: string;
}

export interface PrescriptionDisplayData {
  id: string;
  date: Date;
  type: string;
  left: Array<FlatPrescription>;
  right: Array<FlatPrescription>;
}

@Component({
  selector: 'app-patient-dialog',
  templateUrl: './patient-dialog.component.html',
  styleUrls: ['./patient-dialog.component.css'],
})
export class PatientDialogComponent implements OnInit {
  createPatientError: any;
  updatePatientError: any;
  selectedOrders: Record<string, boolean> = {};
  selectedPrescriptions: Record<string, boolean> = {};
  prescriptionData: Array<PrescriptionDisplayData> = [];
  os: Array<Order> = [];
  selectedPatient: Patient;
  deletePatient: Patient;
  fixDuplicate: boolean = false;

  get orders(): Array<Order> {
    return (this.selectedPatient?.orders as Array<Order>) ?? [];
  }

  get deletedPatientOrders(): Array<Order> {
    return (this.deletePatient?.orders as Array<Order>) ?? [];
  }

  private get orderIds(): Array<string> {
    return this.orders.map((o: Order) => o._id);
  }

  private get deletedPatientOrderIds(): Array<string> {
    return this.deletedPatientOrders.map((o: Order) => o._id);
  }

  get prescriptions(): Array<Prescription> {
    return (this.selectedPatient?.prescriptions as Array<Prescription>) ?? [];
  }

  get deletedPatientPrescriptions(): Array<Prescription> {
    return (this.deletePatient?.prescriptions as Array<Prescription>) ?? [];
  }

  private get prescriptionIds(): Array<string> {
    return this.prescriptions.map((p: Prescription) => p._id);
  }

  private get deletedPatientPrescriptionIds(): Array<string> {
    return this.deletedPatientPrescriptions.map((p: Prescription) => p._id);
  }

  get isCopyDataAvailable(): boolean {
    return !this.deletedPatientPrescriptions.length || !this.deletedPatientOrders.length;
  }

  constructor(
    public dialogRef: MatDialogRef<PatientDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PatientDialogData,
    private patientService: PatientService,
    private orderService: OrderService
  ) {
    console.log(this.data);
  }

  ngOnInit(): void {
    if (this.data.patients.length < 2) {
      this.selectedPatient = this.data.patients[0];
      this.setEyePrescriptionArray();
      this.getPatientOrders();
    } else {
      this.fixDuplicate = true;
    }
  }

  getPrescriptionsData(
    prescription: Prescription,
    key: 'left' | 'right'
  ): Array<{ label: string; value: string }> {
    return Object.entries(prescription[key]).reduce((lacc, [label, value]) => {
      return [
        ...lacc,
        {
          label,
          value,
        },
      ];
    }, []);
  }

  getPatientOs(id?: string) {
    return this.orderService.getPatientOrders(
      id ?? this.selectedPatient?._id,
      'orders'
    );
  }

  getPatientOrders(patientId?: string) {
    this.getPatientOs(patientId)
      .pipe(
        map((orders) =>
          orders.sort(
            (a, b) =>
              new Date(b.dateAdded).getTime() - new Date(a.dateAdded).getTime()
          )
        )
      )
      .subscribe((res: Order[]) => {
        this.os = res;
      });
  }

  setEyePrescriptionArray(remove?: boolean): void {
    const ids = remove ? this.deletedPatientPrescriptions : this.prescriptions;
    this.prescriptionData = ids.reduce((acc, p) => {
      const left = this.getPrescriptionsData(p, 'left');
      const right = this.getPrescriptionsData(p, 'right');
      return [
        ...acc,
        {
          id: p._id,
          date: new Date(p.dateAdded),
          type: p.type,
          left,
          right,
        },
      ];
    }, []);
  }

  selectPatient(checked: boolean, id: string): void {
    if (checked) {
      this.selectedPatient = this.data.patients.find((p) => p._id === id);
      this.deletePatient = this.data.patients.find((p) => p._id !== id);
    } else {
      this.selectedPatient = this.data.patients.find((p) => p._id !== id);
      this.deletePatient = this.data.patients.find((p) => p._id === id);
    }
    this.selectedOrders = {};
    this.selectedPrescriptions = {};
    this.deletePatient.orders.forEach((o: Order) => this.selectOrder(o._id));
    this.deletePatient.prescriptions.forEach((p: Prescription) =>
      this.selectPrescription(p._id)
    );
    this.setEyePrescriptionArray(true);
    this.getPatientOrders(this.deletePatient._id);
  }

  selectOrder(id: string): void {
    if (this.selectedOrders[id]) {
      const { [id]: _, ...rest } = this.selectedOrders;
      this.selectedOrders = rest;
      return;
    }
    this.selectedOrders = { ...this.selectedOrders, [id]: true };
  }

  selectPrescription(id: string): void {
    if (this.selectedPrescriptions[id]) {
      const { [id]: _, ...rest } = this.selectedPrescriptions;
      this.selectedPrescriptions = rest;
      return;
    }
    this.selectedPrescriptions = { ...this.selectedPrescriptions, [id]: true };
  }

  createPatient() {
    const { _id, firstName, patientNotes, ...rest } = this.selectedPatient;
    const patient = {
      ...rest,
      firstName: `${firstName} COPY`,
      alertNotes: true,
      patientNotes: `<p>*** PATIENT COPIED ***</p><p>*** Please update patient data after duplication ***</p>${
        patientNotes ?? ''
      }`,
      orders: Object.keys(this.selectedOrders),
      prescriptions: Object.keys(this.selectedPrescriptions),
      favoriteLines: [],
    };
    return this.patientService.copyPatient(patient).pipe(
      catchError((err) => {
        this.dialogRef.close(false);
        return EMPTY;
      })
    );
  }

  updateExistingPatient() {
    const patient = {
      ...this.selectedPatient,
      orders: this.orderIds.filter((id) => !this.selectedOrders[id]),
      prescriptions: this.prescriptionIds.filter(
        (id) => !this.selectedPrescriptions[id]
      ),
    };
    this.patientService
      .updatedCopiedPatient(patient)
      .pipe(
        catchError((err) => {
          this.dialogRef.close(false);
          return EMPTY;
        })
      )
      .subscribe();
  }

  removeDuplicate() {
    if (!this.isCopyDataAvailable) {
      this.patientService
        .deletePatient(this.deletePatient._id)
        .subscribe(() => this.dialogRef.close(this.deletePatient._id));
    } else {
      const { patientNotes, ...rest } = this.selectedPatient;
      const patient = {
        ...rest,
        alertNotes: true,
        patientNotes: `<p>*** FIXED DUPLICATE ***</p><p>*** Please confirm patient data after fix duplicate ***</p>${
          patientNotes ?? ''
        }`,
        orders: this.deletedPatientOrderIds.concat(this.orderIds),
        prescriptions: this.deletedPatientPrescriptionIds.concat(this.prescriptionIds),
        favoriteLines: Array.from(new Set(this.selectedPatient.favoriteLines.concat(this.deletePatient.favoriteLines))),
      };
      this.patientService
        .updatedCopiedPatient(patient)
        .pipe(
          concatMap(() => this.patientService.deletePatient(this.deletePatient._id)),
          catchError((err) => {
            console.log(err);
            return EMPTY;
          })
        )
        .subscribe(() => {
          this.dialogRef.close(this.selectedPatient._id)
    });
    }
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {
    this.createPatient()
      .pipe(
        concatMap(({ patient }) => {
          this.updateExistingPatient();
          return of(patient);
        })
      )
      .subscribe((patient) => {
        this.dialogRef.close(patient);
      });
  }
}
